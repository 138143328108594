import React from "react"
import { Container, Nav } from "react-bootstrap"
import Telegram from "../../../assets/img/footer/telegram.svg"
import Vk from "../../../assets/img/footer/vk.svg"
import YouKassa from "../../../assets/img/footer/youKassa.svg"
import Youtube from "../../../assets/img/footer/youtube.svg"
import TelegaBtnIco from "../../../assets/img/footer/telegaBntIco.svg"
import * as style from "./Footer.module.scss"
import useClient from "../../hook/useClient"
import { Link } from "gatsby"
import { route } from "../../constants/route"
import IconLogo from "../../../assets/img/logo.svg"
import LinkUi from "../common/linkUi/LinkUI"

function Footer() {
  let pathname = null
  let profileInfo = null
  const isClient = useClient()
  if (isClient) {
    pathname = window.location.pathname
    profileInfo = localStorage.getItem("profileInfo")
      ? JSON.parse(localStorage.getItem("profileInfo"))
      : null
  }
  const isAuth = !!profileInfo
  const isHidden = pathname !== null && pathname.includes("auth")

  return (
    <>
      <div
        className={`${isAuth ? "bg-light text-dark" : "bg-dark text-light"} ${
          isHidden ? "layout_hidden" : ""
        }`}
      >
        <Container fluid={"xxl"} className={"py-5"}>
          <div className={style.footerContainer}>
            <div className={style.logo}>
              <Link to={"/"} className={"d-flex align-items-center nav-link"}>
                <IconLogo />
                <span
                  className={`mx-2 ${
                    isAuth ? "text-dark d-sm-block d-none" : "text-white"
                  } fs-5 text-center  fw-bold`}
                  style={{ marginBottom: "5px" }}
                >
                  адаптер
                </span>
              </Link>
            </div>
            <div className={style.nav}>
              <Nav className={`d-flex flex-column flex-sm-row `}>
                {isAuth ? (
                  <>
                    <LinkUi isAuth={isAuth} to={route.connectios}>
                      Связка
                    </LinkUi>
                    <LinkUi isAuth={isAuth} to={route.tariff}>
                      Тарифы
                    </LinkUi>
                  </>
                ) : (
                  <>
                    <LinkUi isAuth={isAuth} to="/#ability">
                      О платформе
                    </LinkUi>
                    <LinkUi isAuth={isAuth} to="/#link">
                      Тарифы
                    </LinkUi>
                    <LinkUi isAuth={isAuth} to="/#dataBase">
                      База знаний
                    </LinkUi>
                  </>
                )}
              </Nav>
            </div>
            <div className={style.doc}>
              <Link
                className={"nav-link "}
                aria-label={"Пользовательское соглашение"}
                to={route.term}
              >
                Пользовательское соглашение
              </Link>
              <Link
                className={"nav-link  "}
                aria-label={"Политика конфиденциальности"}
                to={route.politics}
              >
                Политика конфиденциальности
              </Link>
            </div>
            <div className={style.social}>
              <div className={"mb-2 "}>Мы онлайн</div>
              <div className={"d-flex gap-2 "}>
                <a
                  className={"nav-link"}
                  aria-label={"telegram nolim"}
                  href="https://t.me/s/nolimcorp"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Telegram />
                </a>
                <a
                  className={"nav-link"}
                  aria-label={"vk nolim"}
                  href="https://vk.com/nolimcorp"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Vk />
                </a>
                <a
                  className={"nav-link"}
                  aria-label={"youtube nolim"}
                  href="https://www.youtube.com/@Nolimcorp"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Youtube />
                </a>
              </div>
            </div>
            <div className={style.community}>
              <div>Комьюнити экспертов</div>
              <div>
                <a
                  href={"https://t.me/adapter_team"}
                  target={"_blank"}
                  className={"btn btn-primary"}
                  style={{ fontSize: "14px" }}
                  rel="noreferrer"
                >
                  Подписаться{" "}
                  <TelegaBtnIco
                    style={{ marginLeft: "8px", marginBottom: "2px" }}
                  />
                </a>
              </div>
            </div>
            <div className={style.support}>
              По техническим вопросам
              <br />
              <a
                href="mailto:adapter@nolim.cc"
                className={isAuth ? "text-dark" : "text-light"}
              >
                adapter@nolim.cc
              </a>
            </div>
          </div>
          <div className={style.divider} />
          <div className={style.underlineFooter}>
            <div className={style.info}>
              <div>
                © 2024 | ИП Петров А.О. | Все права защищены. Копирование
                материалов запрещено.{" "}
              </div>
              <div>ОГРНИП 315784700158808</div>
            </div>
            <div className={`${style.kassa} ${isAuth ? style.kassaLight : ""}`}>
              <YouKassa />
            </div>
            <div className={style.createBy}>
              Created by
              <br />
              <span className={"text-primary"}>Nolim</span>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Footer
