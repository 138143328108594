import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const IntegrationContext = createContext("")

const IntegrationContextProvider = ({ children }) => {
  const fetchData = useStaticQuery(graphql`
    query MyQuery {
      fetch {
        integrationTypes {
          id
          title
          table_field_labels {
            button_link
            button_text
            card_description
            description
            form_fields
            html
            image
            image_list
            is_form_submit
            key
            option1_variants
            option2_variants
            popup_text
            price
            price_old
            product_url
            subtitle_prod
            table_content
            text
            title
            value
          }
          table_fields_schema {
            _schema
            additionalProperties
            required
            type
            properties {
              button_link {
                type
              }
              button_text {
                type
              }
              card_description {
                type
              }
              description {
                type
              }
              form_fields {
                items {
                  additionalProperties
                  properties {
                    key {
                      type
                    }
                    value {
                      type
                    }
                  }
                  required
                  type
                }
                minItems
                type
              }
              html {
                type
              }
              image {
                type
              }
              image_list {
                type
                items {
                  type
                }
              }
              is_form_submit {
                type
              }
              option1_variants {
                items {
                  type
                }
              }
              option2_variants {
                items {
                  type
                }
              }
              popup_text {
                type
              }
              price {
                type
              }
              price_old {
                type
              }
              product_url {
                type
              }
              subtitle_prod {
                type
              }
              table_content {
                minItems
                type
                items {
                  type
                }
              }
              text {
                type
              }
              title {
                type
              }
            }
          }
        }
        accessRights {
          id
          is_authorized_user
          is_replacement_table_fields
          is_table_field_required
          title
        }
      }
    }
  `)

  function removeNullProperties(obj) {
    // Проверяем, является ли obj объектом и не является ли null
    if (obj !== null && typeof obj === "object") {
      // Проходим по всем свойствам объекта
      for (const key in obj) {
        // Рекурсивно обрабатываем, если свойство тоже объект
        if (typeof obj[key] === "object") {
          removeNullProperties(obj[key])
        }
        // Удаляем свойство, если его значение равно null
        if (obj[key] === null) {
          delete obj[key]
        }
      }
    }
  }

  const accessRights = fetchData.fetch.accessRights
  const integrationTypes = fetchData.fetch.integrationTypes.map(item => {
    removeNullProperties(item)
    if (item.id === 5) {
      delete item.table_field_labels.form_fields
    }
    return item
  })
  const value = {
    integrationTypes,
    accessRights,
  }

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  )
}
export default IntegrationContextProvider
