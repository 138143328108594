import React from "react"
import { Link } from "gatsby"

function LinkUi({ children, isAuth, className: customCl, ...restProps }) {
  return (
    <Link
      className={`p-0 nav-link ${!isAuth ? "text-light" : ""} ${customCl}`}
      {...restProps}
    >
      {children}
    </Link>
  )
}

export default LinkUi
