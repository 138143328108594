import React, { useContext, useEffect, useState } from "react"
import { Toast as ToastBS } from "react-bootstrap"
import { ToastContext } from "../../context/ToastContext.js"
import * as style from "./Toast.module.scss"
import SuccessModal from "./SuccessModal"

export default function Toast() {
  const [show, setShow] = useState(true)
  const { error, setError, setSuccess, success } = useContext(ToastContext)

  useEffect(() => {
    setShow(true)
    const timeout = setTimeout(() => {
      if (error !== null) setError(null)
      if (success !== null) setSuccess(null)
      return () => {
        clearTimeout(timeout)
      }
    }, 10000)
  }, [error, success])

  return (
    <>
      {error && (
        <ToastBS
          className={style.toast}
          onClose={() => setShow(false)}
          show={show}
          bg={"danger"}
          data-bs-theme="dark"
        >
          <ToastBS.Header>
            <h6 className={"w-100"}>Ошибка</h6>
          </ToastBS.Header>
          <ToastBS.Body className={"text-light"}>{error}</ToastBS.Body>
        </ToastBS>
      )}
      {success && (
        <ToastBS
          className={style.toast}
          onClose={() => setShow(false)}
          show={show}
          bg={"success"}
          data-bs-theme="dark"
        >
          <ToastBS.Header>
            <div className={"w-100"}>Уведомление</div>
          </ToastBS.Header>
          <ToastBS.Body className={"text-light"}>
            {typeof success === "string" ? (
              success
            ) : (
              <div className={"d-flex flex-column align-items-center gap-4"}>
                {" "}
                {success.message} <SuccessModal message={success.data} />
              </div>
            )}
          </ToastBS.Body>
        </ToastBS>
      )}
    </>
  )
}
