import React, { createContext, useState } from "react"


export const ToastContext = createContext(null)

export const ToastProvider = ({ children }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  return (
    <ToastContext.Provider
      value={
        {
          success,
          setSuccess,
          error,
          setError
        }
      }
    >
      {children}
    </ToastContext.Provider>
  )
}

