import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"

function SuccessModal({ message }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button variant={"outline-light"} onClick={handleShow}>
        Подробнее
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        fullscreen={"sm-down"}
        centered={true}
        className={"text-dark"}
        style={{ zIndex: 999999999999999 }}
      >
        <Modal.Header className={"fw-bold"} closeButton>
          Данные, которые приходят с сервера
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    </>
  )
}

export default SuccessModal
