import React, { useContext } from "react"
import { Link } from "gatsby"
import { AppContext } from "../../context/AppContext"

export default function DocsNav({ data, title }) {
  const { isInitialized } = useContext(AppContext)
  let path = isInitialized ? window.location.pathname : ""
  return (
    <>
      {title && <h4>База знаний</h4>}
      <nav style={{ marginRight: "24px" }}>
        {data.map((item, index) => (
          <Link
            key={index}
            className={`nav-link ${
              path.includes(item) ? "bg-dark text-light" : ""
            }`}
            style={{ paddingLeft: "12px" }}
            to={`/docs/${item}`}
          >
            {item}
          </Link>
        ))}
      </nav>
    </>
  )
}
