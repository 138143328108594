import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import DocsNav from "../../docsNav/DocsNav"

function DocsModal({ data }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className={"d-block d-md-none"}>
      <Button className={"nav-link text-nowrap"} onClick={handleShow}>
        База знаний
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className={" text-dark"}
        centered={true}
        fullscreen={"sm-down"}
      >
        <Modal.Header closeButton>
          <Modal.Title>База знаний</Modal.Title>
        </Modal.Header>
        <Modal.Body onClick={handleClose}>
          <DocsNav data={data} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DocsModal
