import { useEffect, useState } from "react"

const useClient = () => {
  const [hasWindow, setHasWindow] = useState(false)

  useEffect(() => {
    setHasWindow(typeof window !== "undefined")
  })
  return hasWindow
}

export default useClient