import React, { useState } from "react"
import { Button, Offcanvas } from "react-bootstrap"
import IconBurger from "../../../../assets/img/burger.svg"
import IconUser from "../../../../assets/img/user.svg"
import { route } from "../../../constants/route"
import HeaderNav from "../headerNav/HeaderNav"


export default function OffcanvasUi({ router }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <div>
      <Button variant="link" aria-label={"меню"}
              className={"d-lg-none  d-flex align-items-center justify-content-center btn-custom"}
              onClick={handleShow}>
        <IconBurger />
      </Button>
      {show &&
        <Offcanvas show={show} onHide={handleClose} responsive="lg" className={"px-4 py-5"}
                   data-bs-theme={"dark"}>
          <HeaderNav isOffCanvas={true} handleClose={handleClose} />
          <div className={"d-flex d-sm-none flex-column gap-3"}>
            <Button
              variant={"light"}
              className={"btn-custom fw-bold"}
              aria-label={"попробовать бесплатно"}
              onClick={() => {
                router(route.tariff)
                handleClose()
              }}>
              попробовать
              бесплатно
            </Button>
            <Button
              variant={"primary"}
              aria-label={"войти в аккаунт"}
              className={"d-flex align-items-center justify-content-center gap-2  btn-custom"}
              onClick={() => {
                router(route.login)
                handleClose()
              }}
            >
              <IconUser />
              войти
            </Button>
          </div>
        </Offcanvas>
      }
    </div>
  )
}

